.listContainer {
  height: 167px;
  overflow: hidden;
  position: relative;
}

.listContainer:hover {
  overflow-y: auto;
}

.listContainer::-webkit-scrollbar {
  width: 2px;
}

.listContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

.mainContainer {
  box-shadow: 0px 8px 15px 0px #1414141f;
  background-color: "#FFFFFF";
  width: 370px;
  height: 326px;
  border-radius: 6px;
  border-width: 1px;
  border-style: "solid";
  border-color: "#EEEEEE";
  padding: 10px;
}

.checkboxContainer {
  box-shadow: 0px 0px 8px 1px skyblue;
}